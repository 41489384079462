import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./Seamless.css"

const Seamless = ({ src }) => {
  const [formId, setFormId] = useState("")

  const createScriptTag = (source, id) => {
    const script = document.createElement("script")

    script.src = source
    script.async = true
    script.id = id

    document.body.appendChild(script)
  }

  useEffect(() => {
    const id = src.replace("/", "").replace(".js", "")
    const script = document.getElementById(id)

    if (!script && src) {
      setFormId(id)
      createScriptTag(src, id)
    }

    return () => {
      if (script) {
        script.remove()
      }
    }
  }, [src, formId])

  return <div className="seamless-form-wrapper" id="seamless-form-wrapper" />
}

Seamless.propTypes = {
  src: PropTypes.string,
}

export default Seamless
